import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as echarts from "echarts";
import axios from "axios";

const CandlestickChart = () => {
    const navigate = useNavigate();
    const { symbol } = useParams();
    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!symbol) {
            // navigate to /chart/vnindex
            navigate("/chart/vnindex");
        }

        // Lấy parameters từ URL
        const searchParams = new URLSearchParams(window.location.search);
        
        // Lấy timeframe từ query parameter 'tf'
        const tf = searchParams.get('tf');
        let resolution = '1D'; // Mặc định là 1D
        
        // Xác định timeframe và mức độ zoom ban đầu dựa trên timeframe
        let dataZoomStart = 60; // Mặc định hiển thị 40% dữ liệu gần nhất cho 1D
        
        // Kiểm tra timeframe có hợp lệ không và điều chỉnh zoom tương ứng
        if (tf === '5') {
            resolution = tf;
            dataZoomStart = 97; // Hiển thị 3% dữ liệu gần nhất cho khung 5 phút
        } else if (tf === '15') {
            resolution = tf;
            dataZoomStart = 95; // Hiển thị 5% dữ liệu gần nhất cho khung 15 phút
        } else if (tf === '60') {
            resolution = tf;
            dataZoomStart = 80; // Hiển thị 20% dữ liệu gần nhất cho khung 60 phút
        }
        
        // Lấy view mode từ query parameter 'view'
        const view = searchParams.get('view');
        
        // Chỉ áp dụng view=full nếu được chỉ định rõ ràng
        if (view === 'full') {
            dataZoomStart = 0; // Hiển thị 100% dữ liệu khi view=full
        }

        // Tính toán thời gian
        const to = Math.floor(Date.now() / 1000); // Thời gian hiện tại (unix timestamp)
        const from = to - 500 * 24 * 60 * 60; // Lùi lại 500 ngày

        // Fetch dữ liệu từ API sử dụng axios thay vì fetch
        const fetchData = async () => {
            setLoading(true);
            try {
                const apiUrl = `https://vietstock.resys.workers.dev/?symbol=${symbol}&resolution=${resolution}&from=${from}&to=${to}`;
                console.log("Đang fetch dữ liệu từ:", apiUrl);

                // Sử dụng axios với cấu hình tối giản để giảm thiểu vấn đề CORS
                const response = await axios.get(apiUrl, {
                    headers: {
                        Accept: "application/json",
                    },
                    // Không gửi cookies - quan trọng cho CORS
                    withCredentials: false,
                    // Timeout sau 15 giây
                    timeout: 15000,
                });

                // Axios tự động kiểm tra status code và throw error nếu không thành công
                console.log(
                    "Status response:",
                    response.status,
                    response.statusText
                );
                console.log("Headers:", response.headers);

                // Axios tự động chuyển đổi JSON response thành object
                const data = response.data;
                console.log("Dữ liệu nhận được:", data);

                // Kiểm tra dữ liệu chi tiết
                if (data && data.status === "ok") {
                    if (
                        data.data &&
                        Array.isArray(data.data) &&
                        data.data.length > 0
                    ) {
                        console.log(
                            `Đã nhận được ${data.data.length} mục dữ liệu`
                        );
                        renderChart(data, dataZoomStart, resolution);
                    } else {
                        console.warn(
                            "Mảng dữ liệu rỗng hoặc không tồn tại:",
                            data
                        );
                    }
                } else {
                    console.error(
                        "API trả về trạng thái không thành công:",
                        data
                    );
                    setLoading(false);
                }
            } catch (error) {
                console.error("Lỗi khi lấy dữ liệu:", error);

                // Phân tích lỗi chi tiết hơn với axios
                if (error.response) {
                    // Server trả về response với status code nằm ngoài phạm vi 2xx
                    console.error("Response error data:", error.response.data);
                    console.error(
                        "Response error status:",
                        error.response.status
                    );
                    console.error(
                        "Response error headers:",
                        error.response.headers
                    );
                } else if (error.request) {
                    // Request được gửi đi nhưng không nhận được response
                    console.error("Request error:", error.request);
                } else {
                    // Có lỗi khi setting up request
                    console.error("Error message:", error.message);
                }
            }
        };

        fetchData();
    }, [symbol, navigate, window.location.search]);

    const renderChart = (rawData, dataZoomStart = 60, resolution = '1D') => {
        // Đảm bảo chartRef.current tồn tại
        if (!chartRef.current) return;

        // Chuyển symbol thành chữ hoa
        const symbolUppercase = symbol.toUpperCase();

        // Xử lý dữ liệu để phù hợp với định dạng của ECharts
        const dates = rawData.data.map((item) => {
            const date = new Date(item.date);
            return date.toLocaleDateString("vi-VN", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        });

        // Dữ liệu cho candlestick: [open, close, low, high]
        const values = rawData.data.map((item) => [
            item.open,
            item.close,
            item.low,
            item.high,
        ]);

        // Khối lượng giao dịch
        const volumes = rawData.data.map((item) => item.volume / 1000000);

        // Tính EMA 34
        const calculateEMA = (prices, period) => {
            const k = 2 / (period + 1);
            let emaArray = [];
            let ema = prices[0]; // Bắt đầu với giá đầu tiên

            // Tính EMA cho mỗi giá đóng cửa
            prices.forEach((price, i) => {
                if (i === 0) {
                    emaArray.push(price);
                } else {
                    ema = price * k + ema * (1 - k);
                    emaArray.push(parseFloat(ema.toFixed(2)));
                }
            });

            return emaArray;
        };

        // Giá đóng cửa
        const closePrices = rawData.data.map((item) => item.close);

        // Lấy giá đóng cửa gần nhất và tính toán thay đổi
        const lastClosePrice = closePrices[closePrices.length - 1];
        const previousClosePrice =
            closePrices[closePrices.length - 2] || lastClosePrice;
        const priceChange = lastClosePrice - previousClosePrice;
        const priceChangePercent = (priceChange / previousClosePrice) * 100;

        // Tính EMA 34 và EMA 89
        const ema34 = calculateEMA(closePrices, 34);
        const ema89 = calculateEMA(closePrices, 89);

        // Lấy giá trị EMA mới nhất
        const lastEma34 = ema34[ema34.length - 1];
        const lastEma89 = ema89[ema89.length - 1];

        // Tính MA50 cho khối lượng
        const calculateMA = (values, period) => {
            const result = [];
            for (let i = 0; i < values.length; i++) {
                if (i < period - 1) {
                    // Không đủ dữ liệu
                    result.push("-");
                    continue;
                }
                let sum = 0;
                for (let j = 0; j < period; j++) {
                    sum += values[i - j];
                }
                result.push((sum / period).toFixed(2));
            }
            return result;
        };

        const volumeMA50 = calculateMA(volumes, 50);

        // Tính Parabolic SAR
        const calculateSAR = (
            data,
            initialAF = 0.02,
            maxAF = 0.2,
            increment = 0.02
        ) => {
            if (!data || data.length < 2) return [];

            const result = new Array(data.length).fill(null);

            // Xác định xu hướng ban đầu
            let isUpTrend = data[1].close > data[0].close;

            // Thiết lập giá trị ban đầu
            let extremePoint;
            let sar;
            let accelerationFactor = initialAF;

            if (isUpTrend) {
                // Trong xu hướng tăng, SAR ban đầu là mức thấp nhất của 2 nến đầu tiên
                sar = Math.min(data[0].low, data[1].low);
                extremePoint = Math.max(data[0].high, data[1].high);
            } else {
                // Trong xu hướng giảm, SAR ban đầu là mức cao nhất của 2 nến đầu tiên
                sar = Math.max(data[0].high, data[1].high);
                extremePoint = Math.min(data[0].low, data[1].low);
            }

            // SAR cho nến thứ 2 là giá trị khởi tạo
            result[1] = sar;

            // Tính toán SAR cho các nến tiếp theo
            for (let i = 2; i < data.length; i++) {
                // Cập nhật SAR
                sar = sar + accelerationFactor * (extremePoint - sar);

                // Giới hạn giá trị SAR trong xu hướng hiện tại
                if (isUpTrend) {
                    // Trong xu hướng tăng, SAR không được vượt quá low của 2 nến trước
                    sar = Math.min(sar, data[i - 1].low, data[i - 2].low);
                } else {
                    // Trong xu hướng giảm, SAR không được thấp hơn high của 2 nến trước
                    sar = Math.max(sar, data[i - 1].high, data[i - 2].high);
                }

                // Lưu giá trị SAR
                result[i] = parseFloat(sar.toFixed(2));

                // Kiểm tra chuyển đổi xu hướng
                if (
                    (isUpTrend && data[i].low < sar) ||
                    (!isUpTrend && data[i].high > sar)
                ) {
                    // Đảo chiều xu hướng
                    isUpTrend = !isUpTrend;

                    // Reset AF về giá trị ban đầu
                    accelerationFactor = initialAF;

                    // Đảo chiều SAR
                    if (isUpTrend) {
                        sar = Math.min(
                            data[i - 2].low,
                            data[i - 1].low,
                            data[i].low
                        );
                        extremePoint = data[i].high;
                    } else {
                        sar = Math.max(
                            data[i - 2].high,
                            data[i - 1].high,
                            data[i].high
                        );
                        extremePoint = data[i].low;
                    }

                    // Ghi đè giá trị SAR
                    result[i] = parseFloat(sar.toFixed(2));
                } else {
                    // Tiếp tục cùng xu hướng - cập nhật extremePoint nếu cần
                    if (isUpTrend) {
                        if (data[i].high > extremePoint) {
                            extremePoint = data[i].high;
                            // Tăng AF nếu có điểm cực mới
                            accelerationFactor = Math.min(
                                accelerationFactor + increment,
                                maxAF
                            );
                        }
                    } else {
                        if (data[i].low < extremePoint) {
                            extremePoint = data[i].low;
                            // Tăng AF nếu có điểm cực mới
                            accelerationFactor = Math.min(
                                accelerationFactor + increment,
                                maxAF
                            );
                        }
                    }
                }
            }

            return result;
        };

        // Tính toán Parabolic SAR
        const sarValues = calculateSAR(rawData.data);

        // Tạo tiêu đề biểu đồ với thông tin giá
        const formattedChange = priceChange.toFixed(2);
        const formattedChangePercent = priceChangePercent.toFixed(2);
        const priceChangeText = `${
            formattedChange > 0 ? "+" : ""
        }${formattedChange} (${
            formattedChangePercent > 0 ? "+" : ""
        }${formattedChangePercent}%)`;
        
        // Định dạng hiển thị timeframe
        let timeframeDisplay = "D"; // Mặc định cho khung ngày
        if (resolution === "5") {
            timeframeDisplay = "5m";
        } else if (resolution === "15") {
            timeframeDisplay = "15m";
        } else if (resolution === "60") {
            timeframeDisplay = "1H";
        }
        
        const titleText = `${symbolUppercase} (${timeframeDisplay}): ${lastClosePrice.toFixed(
            2
        )} ${priceChangeText}`;
        const subtitleText = `EMA 34: ${lastEma34.toFixed(
            2
        )} | EMA 89: ${lastEma89.toFixed(2)}`;

        // Thiết lập kích thước rõ ràng cho container
        chartRef.current.style.width = "100%";
        chartRef.current.style.height = "90vh";

        // Khởi tạo biểu đồ
        const myChart = echarts.init(chartRef.current);

        // Cấu hình biểu đồ
        const option = {
            title: {
                text: titleText,
                subtext: subtitleText,
                left: "center",
                textStyle: {
                    fontFamily: "Quicksand, sans-serif",
                    fontWeight: "bold",
                    color: priceChange >= 0 ? "#47b262" : "#e85353",
                },
                subtextStyle: {
                    fontFamily: "Quicksand, sans-serif",
                    color: "#333",
                },
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                },
                formatter: function (params) {
                    const candlestickData = params[0];
                    if (!candlestickData) return "";

                    const date = candlestickData.name;
                    const data = candlestickData.data;

                    let tooltipText = `<div style="font-family: Quicksand, sans-serif;"><strong>${date}</strong></div>`;

                    if (data) {
                        tooltipText += `<div>O: ${data[1]}</div>`;
                        tooltipText += `<div>H: ${data[4]}</div>`;
                        tooltipText += `<div>L: ${data[3]}</div>`;
                        tooltipText += `<div>C: ${data[2]}</div>`;
                    }

                    // Thêm thông tin chỉ báo
                    params.forEach((param) => {
                        if (param.seriesName === "EMA 34") {
                            tooltipText += `<div>EMA 34: ${param.data}</div>`;
                        }
                        if (param.seriesName === "EMA 89") {
                            tooltipText += `<div>EMA 89: ${param.data}</div>`;
                        }
                        if (param.seriesName === "Parabolic SAR") {
                            tooltipText += `<div>SAR: ${param.data}</div>`;
                        }
                        if (param.seriesName === "Khối lượng") {
                            tooltipText += `<div>Volume: ${param.data} tr</div>`;
                        }
                        if (param.seriesName === "Khối lượng MA50") {
                            tooltipText += `<div>Volume MA50: ${param.data} tr</div>`;
                        }
                    });

                    return tooltipText;
                },
            },
            legend: {
                data: [
                    "EMA 34",
                    "EMA 89",
                    "Parabolic SAR",
                ],
                top: 60, // Điều chỉnh vị trí xuống dưới phụ đề
                textStyle: {
                    fontFamily: "Quicksand, sans-serif",
                },
            },
            axisPointer: {
                link: { xAxisIndex: "all" },
            },
            grid: [
                {
                    left: "10%",
                    right: "10%",
                    top: "20%", // Tăng thêm không gian cho tiêu đề và phụ đề
                    height: "55%",
                },
                {
                    left: "10%",
                    right: "10%",
                    top: "80%",
                    height: "15%",
                },
            ],
            xAxis: [
                {
                    type: "category",
                    data: dates,
                    scale: true,
                    boundaryGap: true, // Để có khoảng cách ở cả hai bên
                    axisLine: { onZero: false },
                    splitLine: { show: false },
                    axisLabel: {
                        formatter: "{value}",
                        fontFamily: "Quicksand, sans-serif",
                    },
                    min: "dataMin",
                    max: "dataMax",
                    axisTick: {
                        alignWithLabel: true,
                    },
                    // Thêm padding phía bên phải của chart
                    axisPointer: {
                        label: { show: false },
                    },
                    splitNumber: 10,
                    // Bổ sung để tạo khoảng trống bên phải
                    nameGap: 30,
                    axisLabel: {
                        margin: 15,
                        fontFamily: "Quicksand, sans-serif",
                    },
                },
                {
                    type: "category",
                    gridIndex: 1,
                    data: dates,
                    axisLabel: { show: false },
                    boundaryGap: true, // Đồng bộ với trục x chính
                },
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: true,
                    },
                    position: "right", // Chuyển trục giá sang bên phải
                    axisLabel: {
                        fontFamily: "Quicksand, sans-serif",
                        margin: 10,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#ddd",
                            type: "dashed",
                        },
                    },
                },
                {
                    gridIndex: 1,
                    splitNumber: 2,
                    position: "right", // Chuyển trục khối lượng sang bên phải
                    axisLabel: {
                        show: true,
                        fontFamily: "Quicksand, sans-serif",
                        margin: 10,
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                },
            ],
            dataZoom: [
                {
                    type: "inside",
                    xAxisIndex: [0, 1],
                    start: dataZoomStart, // Hiển thị dữ liệu theo tham số
                    end: 100,
                },
                {
                    show: true,
                    xAxisIndex: [0, 1],
                    type: "slider",
                    bottom: 0,
                    start: dataZoomStart,
                    end: 100,
                },
            ],
            series: [
                {
                    name: "Candlestick",
                    type: "candlestick",
                    data: values,
                    itemStyle: {
                        color: "#47b262", // Màu xanh cho nến tăng
                        color0: "#e85353", // Màu đỏ cho nến giảm
                        borderColor: "#47b262",
                        borderColor0: "#e85353",
                    },
                },
                {
                    name: "EMA 34",
                    type: "line",
                    data: ema34,
                    smooth: true,
                    symbol: "none",
                    lineStyle: {
                        color: "#1E90FF", // Màu xanh nước biển
                        width: 1,
                    },
                },
                {
                    name: "EMA 89",
                    type: "line",
                    data: ema89,
                    smooth: true,
                    symbol: "none",
                    lineStyle: {
                        color: "#000000", // Màu đen
                        width: 1,
                    },
                },
                {
                    name: "Parabolic SAR",
                    type: "scatter",
                    data: sarValues,
                    symbol: "circle",
                    symbolSize: 1, // Giảm kích thước chấm xuống còn 1px
                    itemStyle: {
                        color: "#8A2BE2", // Màu tím đậm
                    },
                    z: 5, // Đảm bảo hiện thị trên các series khác
                },
                {
                    name: "Khối lượng",
                    type: "bar",
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    data: volumes,
                    itemStyle: {
                        color: function (params) {
                            const i = params.dataIndex;
                            const closePrice = rawData.data[i].close;
                            const openPrice = rawData.data[i].open;
                            return closePrice >= openPrice
                                ? "#47b262" // Màu xanh cho khối lượng khi giá tăng
                                : "#e85353"; // Màu đỏ cho khối lượng khi giá giảm
                        },
                    },
                },
                {
                    name: "Khối lượng MA50",
                    type: "line",
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    data: volumeMA50,
                    smooth: true,
                    symbol: "none",
                    lineStyle: {
                        color: "#000000", // Màu đen
                        width: 2,
                    },
                },
                // Thêm đường tham chiếu ngang ở giá đóng cửa hiện tại
                {
                    name: "Giá hiện tại",
                    type: "line",
                    markLine: {
                        symbol: ["none", "none"],
                        silent: true,
                        data: [
                            {
                                yAxis: lastClosePrice,
                                lineStyle: {
                                    type: "dashed",
                                    color: "#000",
                                    width: 1,
                                },
                            },
                        ],
                        label: {
                            show: true,
                            position: "end",
                            formatter: "{c}",
                            backgroundColor: "#eee",
                            padding: [3, 6],
                            borderRadius: 3,
                            fontFamily: "Quicksand, sans-serif",
                        },
                    },
                },
            ],
        };

        // Áp dụng cấu hình
        myChart.setOption(option);

        // Mở rộng khoảng trống bên phải
        myChart.setOption({
            grid: [
                {
                    left: "10%",
                    right: "10%",
                    top: "20%", // Điều chỉnh phù hợp với không gian tiêu đề
                    height: "55%",
                    containLabel: true,
                },
                {
                    left: "10%",
                    right: "10%",
                    top: "80%",
                    height: "15%",
                    containLabel: true,
                },
            ],
            xAxis: [
                {
                    boundaryGap: true,
                    axisPointer: {
                        label: { show: false },
                    },
                },
                {
                    boundaryGap: true,
                },
            ],
        });

        // Xử lý thay đổi kích thước
        const handleResize = () => {
            myChart.resize();
        };

        window.addEventListener("resize", handleResize);
        setLoading(false);

        // Dọn dẹp
        return () => {
            window.removeEventListener("resize", handleResize);
            myChart.dispose();
        };
    };

    return (
        <div
            className="w-full h-full flex flex-col relative"
            style={{ fontFamily: "Quicksand, sans-serif" }}
        >
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70 z-10">
                    <div className="text-xl font-semibold">
                        Đang tải dữ liệu...
                    </div>
                </div>
            )}
            <div
                ref={chartRef}
                className="w-full h-full"
                style={{ marginTop: "1em", height: "90vh" }}
            ></div>
        </div>
    );
};

export default CandlestickChart;